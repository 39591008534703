require("../stylesheets/application.css")

require("@rails/actiontext")
require("@rails/activestorage").start()
require("@rails/ujs").start()
require("channels")
require("trix")

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

Turbo.StreamActions.redirect = function () {
  window.location.href = window.location.origin + this.target
}

Turbo.StreamActions.refresh = function () { Turbo.visit(window.location.href) }

// Based in
// https://github.com/hotwired/turbo/issues/294#issuecomment-877842232
document.addEventListener("turbo:before-fetch-request", function (event) {
  const cspNonce = document
    .querySelector("meta[name='csp-nonce']")
    .getAttribute("content")

  event.detail.fetchOptions.headers["Turbo-Referrer"] = window.location.href
  event.detail.fetchOptions.headers["X-Turbolinks-Nonce"] = cspNonce
})

document.addEventListener("turbo:before-cache", function () {
  // Because nonces can only be accessed via their IDL attribute after
  // the page loads, they need to be read via JS and added back as
  // normal attributes in the DOM before the page is cached otherwise
  // on cache restoration visits, the nonces won’t be there!
  document
    .querySelectorAll("script[nonce]")
    .forEach((element) => element.setAttribute("nonce", element.nonce))

  document
    .querySelectorAll("style[nonce]")
    .forEach((element) => element.setAttribute("nonce", element.nonce))
})

// Please see https://github.com/hotwired/turbo-rails/issues/284
// If the issue is being resolved then this code can be removed
document.addEventListener("turbo:before-stream-render", (event) => {
  const validHTML = /<\/?[a-z][\s\S]*>/i.test(event.target.children[0].innerHTML)

  if (!validHTML) {
    event.target.children[0].innerHTML = htmlDecode(event.target.children[0].innerHTML)
  }

  function htmlDecode(input) {
    const e = document.createElement("div")
    e.innerHTML = input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue
  }
})

import "config/trix"

import "controllers"
import "elements"

import "vendor/hubspot"

import "../components/date_time_picker"
import "../components/confirm_dialog"

import LocalTime from "local-time"
LocalTime.start()

import "@lottiefiles/lottie-player"
