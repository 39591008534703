export default class Hubspot {
  static init() {
    delete window["HubSpotConversations"]

    // HubSpot Script Loader. Please do not block this resource. See more: http://hubs.ly/H0702_H0
    // Hubspot Chat Widget
    !(function (t, e, r) {
      if (!document.getElementById(t)) {
        var n = document.createElement("script")
        for (var a in ((n.src = "https://js-eu1.usemessages.com/conversations-embed.js"),
        (n.type = "text/javascript"),
        (n.id = t),
        r))
          r.hasOwnProperty(a) && n.setAttribute(a, r[a])
        var i = document.getElementsByTagName("script")[0]
        i.parentNode.insertBefore(n, i)
      }
    })("hubspot-messages-loader", 0, {
      "data-loader": "hs-scriptloader",
      "data-hsjs-portal": 8639698,
      "data-hsjs-env": "prod",
      "data-hsjs-hublet": "eu1",
    })
    var _hsp = (window._hsp = window._hsp || [])
    _hsp.push(["addEnabledFeatureGates", []])

    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: "#hubspot-chat-container",
    }
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load()
      },
    ]
  }

  static cleanUp() {
    document
      .querySelectorAll(
        'script[src*="https://js-eu1.usemessages.com/conversations-embed.js"], [data-hsjs-portal]'
      )
      .forEach((element) => {
        element.parentNode.removeChild(element)
      })
  }
}

document.addEventListener("turbo:before-cache", Hubspot.cleanUp)
document.addEventListener("turbo:load", Hubspot.init)
document.addEventListener("turbo:render", Hubspot.init)

// if turbo has been disabled (while in development for example), use the standard
// load event instead
if (!window.Turbo) window.addEventListener("load", Hubspot.init)
