class ContentFrame extends HTMLElement {
  static get observedAttributes() {
    return ["url"]
  }

  constructor() {
    super()
    this.currentUrl == null
  }

  connectedCallback() {
    const url = this.getAttribute("url")
    this.loadContent(url)
  }

  attributeChangedCallback() {
    const url = this.getAttribute("url")
    this.loadContent(url)
  }

  loadContent(newUrl) {
    if (newUrl == this.currentUrl) {
      return
    }

    this.currentUrl = this.getAttribute("url")
    fetch(this.currentUrl)
      .then((response) => {
        return response.text()
      })
      .then((text) => {
        this.innerHTML = text
      })
  }
}

customElements.define("content-frame", ContentFrame)
